import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { isBrowser, setFormUtmParams, submitHubspotForm } from "../../utils"
import { Grid } from "@mui/material"
import CustomInput from "../custom-input/custom-input.component"

import * as S from "./lets-talk-form.styles"
import { Typography } from "@mui/material"
import { contactFormApi } from "../../apis/apis"

const LetsTalkForm = ({ setSuccessMessage }) => {
  const portalId = "test"
  const formId = "test"
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  })

  const onSubmit = async data => {
    setErrorMessage("")
    setIsLoading(true)

    const form = new FormData()
    form.append("yourName", data.firstname)
    form.append("yourEmail", data.email)
    form.append("message", data.message)

    contactFormApi
      .post(`/246/feedback`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(response => {
        setIsLoading(false)
        if (response.data) {
          if (response.data.status === "mail_sent") {
            setSuccessMessage(response.data.message)
            if (isBrowser()) {
              window.gtag("event", "form_submission", {
                event_category: "Form",
                event_action: "Submit",
                event_label: "Contact form drawer",
              })
            }
          } else {
            setErrorMessage(response.data.message)
          }
        }
      })
  }

  useEffect(() => {
    setFormUtmParams(setValue)
  }, [])
  return (
    <form
      data-form-id={formId}
      data-portal-id={portalId}
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
    >
      {isLoading && (
        <S.FormSpinner>
          <S.CustomSpinner />
        </S.FormSpinner>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomInput
            register={register}
            label="Nombre y Apellidos"
            isRequired
            errors={errors}
            name="firstname"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            register={register}
            label="Correo electrónico"
            isRequired
            errors={errors}
            name="email"
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <S.MessageInput
            register={register}
            label="Mensaje"
            isRequired
            errors={errors}
            name="message"
            rowsMax={4}
          />
        </Grid>
      </Grid>
      {errorMessage && (
        <Typography style={{ marginTop: "2em" }}>
          <b>{errorMessage}</b>
        </Typography>
      )}
      <S.SubmitBtnWrapper>
        <S.CustomButton type="submit">Enviar</S.CustomButton>
      </S.SubmitBtnWrapper>
    </form>
  )
}
export default LetsTalkForm
