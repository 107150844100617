import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Button from "@mui/material/Button"
import { darken } from "@mui/system"

export const CustomButton = styled(Button)`
  color: white;
  background-color: ${({ theme, color }) =>
    theme.palette[color]
      ? theme.palette[color].main
      : theme.palette.primary.main};
  padding: 0.5em 1.5em;
  border: 1px solid transparent;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 500 !important;
  border-radius: ${({ theme }) => theme.typography.pxToRem(24)};
  text-transform: unset;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0.5em 1.7em;
  }

  &.light {
    background-color: white;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &.darkBorder {
    color: black;
    border: 1px black solid;
    background-color: transparent;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  &.lightBorder {
    color: white;
    border: 2px solid rgb(151, 151, 151, 0.2);
    background-color: transparent;

    &:hover {
      border: 2px solid transparent;
      background-color: white !important;
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  &.whiteBorder {
    color: white;
    border: 2px solid white;
    background-color: transparent;

    &:hover {
      background-color: white !important;
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  &:not(.light):not(.darkBorder):hover {
    background-color: ${({ theme, color }) =>
      darken(
        theme.palette[color]
          ? theme.palette[color].main
          : theme.palette.primary.main,
        0.1
      )};
    //border: 1px solid ${({ theme }) =>
      darken(theme.palette.primary.main, 0.1)};
  }

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background: lightgray;
    border-color: lightgray;
    cursor: not-allowed;
  }
`

export const CustomButtonLink = styled(AniLink)`
  text-decoration: none;
  transition: all 0.3s ease;
`
