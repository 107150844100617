import styled from "styled-components"
import { Container, Drawer, IconButton, Typography } from "@mui/material"
import { Grid } from "@mui/material"

import Logo from "../../assets/icons/A_horz_logo.svg"

export const Wrapper = styled(Drawer)`
  .MuiDrawer-paper {
    height: 100%;
    padding: 1em;
    background-color: ${({ theme }) => theme.palette.primary.main};
    width: 100%;
    ${({ theme }) => theme.breakpoints.up("md")} {
      width: 710px;
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      padding: 2em;
    }
  }
`

export const InnerWrapper = styled.div`
  position: relative;
  color: white;
  height: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 2em 4em;
  }
`
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  //margin-bottom: 6.5em;
`
export const LogoContainerMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-bottom: 6.5em;
`
export const ShortLogo = styled(Logo)`
  width: 144px;
  height: auto;
  //margin-bottom: 3em;
  // ${({ theme }) => theme.breakpoints.down("sm")} {
  //   width: 150px;
  // }
`

export const CloseButton = styled(IconButton)`
  //position: absolute;
  //top: 1.5rem;
  //right: 2rem;

  // ${({ theme }) => theme.breakpoints.down("md")} {
  //   //position: relative;
  //   width: 30px;
  //   padding: 0;
  //   height: auto;
  //   right: 0;
  //   top: -0.3rem;
  // }
  svg {
    &:hover {
      transition: 0.3s ease-in-out;
      transform: scale(1.2);
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      width: 35px;
      height: 35px;
    }
  }
`

export const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(60)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.secondary.main};
  max-width: 430px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(34)};
    line-height: ${({ theme }) => theme.typography.pxToRem(44)};
    margin-top: 2rem;
  }
`

export const Description = styled(Typography)`
  margin-top: 2em;
  color: rgba(255, 255, 255, 0.5);
  padding-bottom: 3em;
`

export const InfoGrid = styled(Grid)`
  padding-top: 2em;
  padding-bottom: 3em;
`

export const InfoCol = styled(Grid)`
  span {
    display: block;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
    margin-bottom: 0.5em;
  }
  p,
  a {
    display: block;
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    line-height: ${({ theme }) => theme.typography.pxToRem(52)};
    text-decoration: none;
    color: ${({ theme }) => theme.palette.secondary.main};
    letter-spacing: 0;
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(18)};
      line-height: ${({ theme }) => theme.typography.pxToRem(40)};
    }
  }
`
export const FormGrid = styled(Grid)`
  margin-top: 4em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 1em;
  }
`
export const InfoForm = styled.span`
  display: block;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  margin-bottom: 0.5em;
`
export const InfoContainer = styled.div`
  display: flex;
  column-gap: 2em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`
export const Content = styled.div``

export const SuccessMessage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  padding: 1em;

  a {
    color: white;
  }

  p {
    color: white;
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: 2rem;
    }
  }
`
