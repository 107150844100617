import React from "react"

import * as S from "./custom-button.styles"
import PropTypes from "prop-types"
import { useTheme } from "@mui/system"

const CustomButton = props => {
  const { fullWidth, className, children, href } = props
  const theme = useTheme()
  return (
    <>
      {href ? (
        <S.CustomButtonLink
          to={href}
          cover
          direction="right"
          duration={2}
          bg={theme.palette.primary.main}
          {...props}
        >
          <S.CustomButton
            className={`custom-button ${className}`}
            disableElevation
            fullWidth={fullWidth ? true : null}
            variant="contained"
          >
            {children}
          </S.CustomButton>
        </S.CustomButtonLink>
      ) : (
        <S.CustomButton
          className={`custom-button ${className}`}
          disableElevation
          fullWidth={fullWidth ? true : null}
          variant="contained"
          {...props}
        >
          {children}
        </S.CustomButton>
      )}
    </>
  )
}

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
}

export default CustomButton
