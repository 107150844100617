import React, { useContext, useState } from "react"
import * as S from "./lets-talk-drawer.styles.jsx"
import Close from "../../assets/icons/close-btn.svg"
import { DrawerContext } from "../../provider"
import LetsTalkForm from "../lets-talk-form/lets-talk-form.component"
import { useMediaQuery, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const LetsTalkDrawer = () => {
  const { isFormDrawerOpen, setIsFormDrawerOpen } = useContext(DrawerContext)
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.down("sm"))
  const [successMessage, setSuccessMessage] = useState(null)
  return (
    <S.Wrapper
      anchor="right"
      open={isFormDrawerOpen}
      transitionDuration={700}
      onClose={() => setIsFormDrawerOpen(false)}
    >
      <S.InnerWrapper>
        {isMD ? (
          <S.LogoContainerMobile>
            <S.ShortLogo />
            <S.CloseButton onClick={() => setIsFormDrawerOpen(false)}>
              <CloseIcon style={{ color: "#F8F8F8" }} />
            </S.CloseButton>
          </S.LogoContainerMobile>
        ) : (
          <S.LogoContainer>
            <S.CloseButton onClick={() => setIsFormDrawerOpen(false)}>
              <Close />
            </S.CloseButton>
          </S.LogoContainer>
        )}
        {!successMessage ? (
          <>
            <S.Title>Contáctanos y manos a la obra</S.Title>
            {/*<S.Description>Contact us and let’s get to work.</S.Description>*/}
            <S.InfoGrid container spacing={3}>
              {/*<S.InfoCol item xs={12}>*/}
              {/*  <span>Call us today</span>*/}
              {/*  <a href="tel:+15085775817">+1-508-577-5817</a>*/}
              {/*</S.InfoCol>*/}
              <S.InfoCol item xs={12}>
                <span>Escríbenos a</span>
                <a href="mailto:hello@aftershock.agency">
                  hello@aftershock.agency
                </a>
              </S.InfoCol>
              {/*<S.InfoCol item xs={12}>*/}
              {/*  <span>HQ</span>*/}
              {/*  <Typography>300 Oregon St #508, Hollywood, FL, 33019</Typography>*/}
              {/*</S.InfoCol>*/}
              <S.FormGrid item xs={12}>
                <S.InfoForm>¿Listo para empezar?</S.InfoForm>
                <LetsTalkForm setSuccessMessage={setSuccessMessage} />
              </S.FormGrid>
            </S.InfoGrid>
          </>
        ) : (
          <S.SuccessMessage>
            <p>{successMessage}</p>
          </S.SuccessMessage>
        )}
      </S.InnerWrapper>
    </S.Wrapper>
  )
}
export default LetsTalkDrawer
